<template>
  <div class="main" @click="showQR()">
    <div class="qr__block">
        <qrcode-vue :value="qrValue" class="qr" :size="size"></qrcode-vue>
        <h3 class="mt-3">Просканируйте QR-код через приложение Egov-mobile</h3>
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'

 export default {
    props: {
      qrValue: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        size: 300,
      }
    },
    components: {
      QrcodeVue 
    },
    methods: {
      showQR(){
              this.$emit('showQR');
        }
    },
 }
</script>

<style style="scope">
  .main {
  width: 100%;
  height: 120%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  overflow-y: scroll;
  transform: translateY(0%);
}

.qr__block {
  width: 320px;
  height: 100%;
  margin: auto;
  margin-top: 10%;
  text-align: center;
  z-index: 15;
}
</style>